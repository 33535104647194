
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import draggable from 'vuedraggable';

@Component({
  components: {
    draggable,
  },
})
export default class ConfirmPopup extends mixins() {
  @Prop({ type: String, default: 'Delete Confirmation' }) header!: string;
  @Prop({ type: String, default: 'Are you sure you want delete?' })
  question!: string;
  @Prop({ type: String, default: 'All information will be lost.' })
  information!: string;
  @Prop({ type: String, default: 'Delete' }) confirmBtnText!: string;
  @Prop({ type: String, default: 'Cancel' }) cancelBtnText!: string;
  @Prop({ type: String, default: 'danger' }) popupType!: string;
}
